import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../components/Meta";
import NavPagination from "../components/NavPagination";
import OfferLine from "../components/OfferLine";
import FilterIcon from "../components/svg/FilterIcon";
import BtnAddFav from "../components/utils/BtnAddFav";
import Input from "../components/utils/Input";
import Loader from "../components/utils/Loader";
import Select from "../components/utils/Select";
import ServerSwitcher from "../components/utils/ServerSwitcher";
import useIsMobile from "../hooks/isMobile";
import useDebounce from "../hooks/useDebounce";
import { getFavorites, toggleFavorite } from "../services/favorite";
import { getGame } from "../services/game";
import { removeDescendants } from "../helpers/all";
import { Modal } from "react-bootstrap";
import { FaSort, FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";

const Game = () => {
  const { id } = useParams();
  const isMobileLG = useIsMobile("1109px");
  const [filterShow, setFilterShow] = useState(!isMobileLG ? true : false);
  const searchParams = new URLSearchParams(location.search);
  const auth = useSelector((state) => state.auth.isAuth);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const favorite = useSelector((state) => state.favorite.items);
  const [sum, setSum] = useState(0);
  const [fav, setFav] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 900);

  const status = useSelector((state) => state.auth.user.status);
  const [showAdvice, setShowAdvice] = useState(false);
  const handleCloseAdvice = () => setShowAdvice(false);
  const handleShowAdvice = () => setShowAdvice(true);
  const [sortState, setSortState] = useState({
    label: 'Сортировка',
    value: null,
    color: 'grey',
  });

  const sortOptions = [
    { value: null, label: 'Сортировка', color: 'grey' },
    { value: 'more', label: 'Цена по возрастанию', color: 'blue' },
    { value: 'less', label: 'Цена по убыванию', color: 'blue' },
  ];

  const handleSortClick = () => {
    const currentIndex = sortOptions.findIndex((option) => option.value === sortState.value);
    const nextIndex = (currentIndex + 1) % sortOptions.length;
    setSortState(sortOptions[nextIndex]);
  };


  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      param: searchParams.get("param") ? parseInt(searchParams.get("param")) : null,
      region: searchParams.get("region") ? parseInt(searchParams.get("region")) : null,
    },

  });
  // useEffect(() => {
  //   if (!data.param || data.param != parseInt(searchParams.get("param"))) {
  //     setValue("param", (searchParams.get("param") ? parseInt(searchParams.get("param")) : null))
  //   }
  // }, [searchParams.get("param")]);


  // useEffect(() => {
  //   if (!data.region || data.region != parseInt(searchParams.get("region"))) {
  //     setValue("region", (searchParams.get("region") ? parseInt(searchParams.get("region")) : null))
  //   }
  // }, [searchParams.get("region")]);

  const data = useWatch({ control });
  const [displayedProducts, setDisplayedProducts] = useState({
    loading: true,
    items: [],
    pagesCount: [],
  });
  const [games, setGames] = useState({ items: [], loading: true });
  const [opt, setOpt] = useState();

  const onSaveOption = (option) => {
    setValue('option', removeDescendants(data, option));
  };

  const maxOption = (tree) => {
    return (
      <Col xs={12} sm={6} md={4}>
        <Row>
          <Col xs={12} sm={12} md={4} className="d-flex align-items-center">
            <span>{tree.title}</span>
          </Col>
          <Col xs={12} sm={12} md={4} className="d-flex align-items-center">
            <Input
              placeholder="от"
              type={"text"}
              value={data.option && data.option.find(e => e.parent == tree.id) && data.option.find(e => e.parent == tree.id).min}
              onChange={(g) => { onSaveOption({ ...tree, min: g !== "" ? parseInt(g) : "all" }) }}
            />
          </Col>
          <Col xs={12} sm={12} md={4} className="d-flex align-items-center">
            <Input
              placeholder="до"
              type={"text"}
              value={data.option && data.option.find(e => e.parent == tree.id) && data.option.find(e => e.parent == tree.id).max}
              onChange={(g) => { onSaveOption({ ...tree, max: g !== "" ? parseInt(g) : "all" }) }}
            />
          </Col>
        </Row>
      </Col>
    );
  };
  const renderSelects = (tree) => {
    if (tree.children && tree.children.length > 0) {
      if (tree.children.length == 1 && !data.option.find(e => e.parent == tree.id)) {
        onSaveOption(tree.children[0])

      }
      if (tree.children[0].data?.max) {
        return tree.children.map(child =>
          maxOption(child)
        );
      }
      else {
        return (
          <>
            {tree.children.length > 1 && < Col xs={12} sm={6} md={4}>
              <Select
                value={data.option && data.option.find(e => e.parent == tree.id) && data.option.find(e => e.parent == tree.id).id}
                title={tree?.title}
                onClick={(e) => onSaveOption(e.data)}
                data={[
                  { value: null, data: tree, title: tree.title },
                  ...(tree?.children?.sort((a, b) => a.priority - b.priority).map((item) => ({ value: item.id, data: item, title: item.title })))
                ]}
              />
            </Col >}
            {data.option && data.option.find(e => e.parent == tree.id) &&
              renderSelects(data.option.find(e => e.parent == tree.id))
            }
          </>
        );
      }
    }
    return null;
  };
  useEffect(() => {
    getGame({ param: searchParams.get("param") ?? null, region: searchParams.get("region") ?? null, server: data.server, id, })
      .then((res) => {
        setGames(prev => ({ ...prev, items: res, loading: false }));

        let servers;
        if (!parseInt(searchParams.get("param"))) {
          const sortedParams = [...res?.category?.params]?.sort((a, b) => a.priority - b.priority);
          const firstParamId = sortedParams[0]?.id;
          const regionId = res.category.regions?.length > 0 ? [...res.category.regions].sort((a, b) => a.priority - b.priority)[0].id : '';
          navigate(`/${id}/?${regionId ? `region=${regionId}&` : ''}${firstParamId ? `param=${firstParamId}` : ''}`);
        }
        let optionsIndex = res.category.params.findIndex((e) => e.id === parseInt(searchParams.get("param")));
        let param = res.category.params[optionsIndex];
        let one = param.data?.one;
        let currency = param.data?.currency;
        let serverView = param.data?.serverView;
        let options = createTree(param.options, 'id', 'parent', null).sort((a, b) => a.id - b.id);

        let serverIndex = res.category.regions.findIndex(
          (e) => e.id === parseInt(searchParams.get("region"))
        );

        let region
        if (serverIndex > -1) {
          region = res.category.regions[serverIndex].id
        }
        if (!serverView) {
          if (serverIndex > -1) {
            servers = res.category.regions[serverIndex].servers.sort((a, b) => a.priority - b.priority);
          }
        }
        reset({
          ...data,
          param: param.id,
          region: region,
          categoryId: res.category.id,
          uid: res.category.uid,
          game: res.category,
          notDesc: currency ? currency : null,
          serverView: serverView ? serverView : null,
          one: one ? one : null,
          servers: servers ? servers : null,
          options: options ? options : null,
          option: null,
        });
        setOpt(param.options);
      })
      .catch(() => setGames(prev => ({ ...prev, loading: false })));
  }, [searchParams.get("param"), searchParams.get("region"), data.server, id]);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const getPage = () => {
    var onlineData
    if (data?.online) {
      onlineData = games?.items?.products?.filter(product => product.user.online.status == data.online)
    }
    var products = onlineData ? onlineData : games?.items?.products;
    var filteredData = onlineData && onlineData;
    if (data?.option) {
      filteredData = products.filter(product => {
        return data.option.every(optionId => {
          if (optionId && optionId.id != null) {

            if (optionId.max || optionId.min) {
              if ((optionId.max == "all" || !optionId.max) && (optionId.min == "all" || !optionId.min)) {
                return true;
              }
              return product.options.some(dataItem => {
                if (dataItem.optionId === optionId.id) {
                  const value = parseInt(dataItem.value);
                  if ((optionId.min == "all" || !optionId.min || value >= optionId.min) && (optionId.max == "all" || !optionId.max || value <= optionId.max)) {
                    return true;
                  }
                }
              });
            }
            else {
              return product.options.some(dataItem => {
                if (dataItem.optionId === optionId.id) {
                  return true;
                } else {
                  let parent = opt.find((e) => e.id === dataItem.optionId);
                  while (parent.parent) {
                    if (parent.parent === optionId.id) {
                      return true;
                    }
                    parent = opt.find((e) => e.id === parent.parent);
                  }
                  return false;
                }
              });
            }
          } else {
            return true;
          }
        });
      });
    }
    var totalProducts = (filteredData ?? games?.items?.products) ?? [];
    if (debouncedSearchTerm) {
      totalProducts = totalProducts.filter(item =>
        item.desc.toLowerCase().includes(searchTerm.toLowerCase())
      );

    }
    if (sortState.value) {
      if (sortState.value == "more") {
        totalProducts = totalProducts.sort((a, b) => a.total - b.total)
      }
      if (sortState.value == "less") {
        totalProducts = totalProducts.sort((a, b) => b.total - a.total)
      }
    }
    const productsPerPage = 30;
    var indexOfLastProduct = currentPage * productsPerPage;
    var indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    setDisplayedProducts({ pagesCount: Math.ceil(totalProducts.length / productsPerPage), items: totalProducts.slice(indexOfFirstProduct, indexOfLastProduct) });
  }

  useEffect(() => {
    if (games?.items?.products) {
      getPage()
    }
  }, [games?.items?.products, currentPage, data.option, debouncedSearchTerm, data.online, sortState.value]);


  const createTree = (data, idProp, parentProp, parentId) =>
    data
      .filter((n) => parentId === (n[parentProp] ?? null))
      .map((n) => ({
        ...n,
        children: createTree(data, idProp, parentProp, n[idProp]),
      }));

  const onFav = useCallback(() => {
    dispatch(toggleFavorite({ categoryId: data?.categoryId, regionId: [...data?.game?.regions].sort((a, b) => a.priority - b.priority)[0]?.id, paramId: [...data?.game?.params].sort((a, b) => a.priority - b.priority)[0]?.id }));
  }, [data, id]);

  useEffect(() => {
    setFav(favorite?.find(el => el.categoryId == games?.items?.category?.id) ? 1 : 0);
  }, [favorite, data.game]);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/account/offers/add', { state: data });
  };
  if (games.loading) {
    return <Loader full />;
  }
  return (
    <>
      <main>
        <Meta title={games.items?.category?.title} />
        <Container>
          {/* <NavBreadcrumbs /> */}
          <section className="game-page pt-4 pt-sm-5 mb-6 min-vh-100">
            <div className="d-md-flex align-items-center justify-content-between mb-4 mb-sm-2">
              <h1 className="mb-md-0">{games.items?.category?.title}</h1>
              {auth && <BtnAddFav favo={fav} onFav={status ? onFav : handleShowAdvice} />}
            </div>

            {/* Game regions ---------------------------------------------------------*/}
            {games?.items?.category?.regions?.length > 0 && [...games?.items?.category?.regions].sort((a, b) => a.priority - b.priority)[0].status ? (
              <ServerSwitcher
                data={data}
                id={id}
                active={data.region}
                serversArr={games.items.category.regions.sort((a, b) => a.priority - b.priority)}
              // onChange={(e) => setValue("region", e)}
              />
            ) : ""}

            {/* Game image & description ------------------------------------------ */}
            {games?.items?.category?.desc &&
              <Row className="mt-4">
                {/* <Col xs={12} lg={7} xl={8}>
              {image && (
                <img
                  src={image}
                  alt={games.items?.category?.title}
                  className="main-img mb-4 mb-lg-0"
                />
              )}
            </Col> */}
                <Col xs={12} className="achromat-1 fs-11">
                  <p>
                    {games?.items?.category?.desc}
                  </p>
                </Col>
              </Row>
            }

            {/* Categories ----------------------------------------------------------- */}
            <ul className="list-unstyled d-flex flex-wrap mt-3 mt-sm-4">
              {games?.items?.category?.params?.length > 0 &&
                games.items.category.params.sort((a, b) => a.priority - b.priority).map((param) => (
                  <li key={param.id}>
                    <Link to={`/${data.uid ?? data.categoryId ?? id}/?${data.region ? `region=${data.region}&` : ''}${param.id ? `param=${param.id}` : ''}`}
                      className={`${param.id == data.param ? "active" : ""
                        } btn-7 flex-column mb-2 me-2 me-lg-4`}
                    >
                      {param.title}
                    </Link>
                  </li>
                ))}
            </ul>

            {/* Main parameters -------------------------------------------------- */}
            <div action="" className="mt-2 mt-sm-3 mb-3">
              {isMobileLG && (
                <button
                  onClick={() => setFilterShow(!filterShow)}
                  type="button"
                  className="dark-blue fs-12 fw-5 mb-3"
                >
                  <span className="me-2">Фильтры</span>
                  <FilterIcon className="fs-12" />
                </button>
              )}
              {filterShow && (data?.servers?.length > 0 || data?.options?.length > 0) && (
                <fieldset className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                  {/* Servers */}
                  {data?.servers && data?.servers.length > 0 && (
                    <Col>
                      <Select
                        value={data.server}
                        title="Сервер"
                        onClick={(e) => setValue("server", parseInt(e.value))}
                        data={data.servers.sort((a, b) => a.priority - b.priority).map((item) => ({
                          value: item.id,
                          title: item.title,
                        }))}
                      />
                    </Col>
                  )}



                  {/* Params */}
                  {data?.options?.length > 0 &&
                    data.options.map((e, i) => {
                      return (
                        <>
                          {e.data?.max ?
                            maxOption(e, i)
                            :
                            renderSelects(e, i)
                          }
                        </>
                      )
                    })
                  }
                </fieldset>
              )}
            </div>
            <fieldset className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 d-flex align-items-center">
              {/* Search */}
              <Col xs={12} sm={12} md={6}>
                <div className="form-search-2 W-100">
                  <input
                    type="search"
                    placeholder="Поиск по описанию..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {!searchTerm &&
                    <button type="button">
                      <FiSearch />
                    </button>
                  }
                </div>
              </Col>
              <Col xs={12} sm={12} md={3}>
                <div className="h-100 W-100 d-flex align-items-center">
                  <span>
                    Только продавцы онлайн:
                  </span>
                  <label className="switch ms-2">
                    <input
                      onChange={(e) => setValue("online", e.target.checked ? 1 : 0)}
                      type="checkbox"
                      checked={data?.online && data.online === 1}
                    />
                  </label>
                </div>
              </Col>

              <Col xs={12} sm={12} md={3}>
                {auth &&
                  <button className="btn-7 w-xs-100 mb-4 ms-auto me-2 me-lg-4" onClick={handleNavigate}>
                    <span className="fw-5">Создать лот</span>
                  </button>
                }
              </Col>
            </fieldset>

            {displayedProducts?.items?.length > 0 ?
              <div>


                <ul className={data.servers && data.servers.length > 0 ? "lot-line-heading mt-4" : "lot-line-no-heading mt-4"}>
                  {data?.servers && data.servers.length > 0 && <li className="lot-line-server">Сервер</li>}
                  <li className={data.servers && data.servers.length > 0 ? "lot-line-descr" : "lot-line-no-descr"}>{!data.notDesc ? "Описание" : "Доступно"}</li>
                  <li className={data.servers && data.servers.length > 0 ? "lot-line-user" : "lot-line-no-user"}>Продавец</li>
                  <li className={data.servers && data.servers.length > 0 ? "lot-line-price" : "lot-line-no-price"}
                    onClick={handleSortClick}>Цена <span

                    >
                      {sortState.value == "more" ? <FaSortAmountDownAlt /> :
                        sortState.value == "less" ? <FaSortAmountDown /> : <FaSort />}
                    </span></li>
                </ul>

                <ul className="mt-2 mt-sm-3 mb-4 mt-lg-0 list-unstyled g-3 g-sm-4 g-lg-0 row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-1">
                  {displayedProducts?.items?.length > 0 && displayedProducts.items.map((item) => (
                    <li>
                      <OfferLine {...item} notDesc={data.notDesc} />
                    </li>
                  ))}
                </ul>
                <NavPagination totalPages={displayedProducts.pagesCount} onPageChange={onPageChange} />
              </div>
              :
              <div className="page-game-offers d-flex align-items-center justify-content-center mt-4">
                <h3>
                  Нет объявлений
                </h3>
              </div>
            }
          </section>
        </Container>
      </main >
      <Modal show={showAdvice} onHide={handleCloseAdvice} size={"md"} centered>
        <Modal.Body>

          <h5 className="text-center">Пожалуйста, потвердите почту.</h5>
          <div className="d-flex justify-content-center mt-4">
            <button type="button" className="btn-4" onClick={handleCloseAdvice}>
              Отмена
            </button>

            <Link to="/activate" onClick={handleCloseAdvice} className="btn-1 ms-4">
              Хорошо
            </Link>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Game;
