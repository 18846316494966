import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { customPrice, getImageURL } from '../helpers/all'
import StarRating from './utils/StarRating'

const ReviewCard = ({ createdAt, text, author, id, value, order }) => {
    const image = getImageURL({ path: author.media, type: "user" })
    const [clampOff, setClampOff] = useState(false);

    const toggleClamp = () => {
        setClampOff(!clampOff);
    };
    return (
        <div className='review-block'>
            <Link to={`/profile/${author.id}`} className='user'>
                <img src={image ? image : "/imgs/user.jpg"} alt={author.nickname} />
                <span className="achromat-3">{author.nickname}</span>
            </Link>
            <div className="flex-1 ms-2 ms-sm-4">

                <div className="d-sm-flex align-items-center mb-2">
                    {order?.total &&
                        <span className="achromat-3">{customPrice(order?.total)}</span>
                    }
                </div>

                <StarRating value={value} className="justify-content-start ms-0 mb-3" />
                <p className={clampOff ? 'clamp-off' : ''} onClick={toggleClamp}>{text}</p>
            </div>
        </div >
    )
}

export default ReviewCard