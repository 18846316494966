import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import NavPagination from "../../components/NavPagination";
import Operation from "../../components/Operation";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { customPrice } from "../../helpers/all";
import Meta from "../../components/Meta";
import { createTransaction, getTransactions } from "../../services/transaction"
import Loader from '../../components/utils/Loader';
import Table from "react-bootstrap/Table";
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Input from '../../components/utils/Input';
import { useForm, useWatch } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import Select from '../../components/utils/Select';

const Finance = () => {
  const [tab, setTab] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [price, setPrice] = useState()
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const [showAdvice, setShowAdvice] = useState(false);
  const handleCloseAdvice = () => setShowAdvice(false);
  const handleShowAdvice = () => setShowAdvice(true);
  const [url, setUrl] = useState()

  const { user } = useSelector((state) => state.auth);
  const [transactions, setTransactions] = useState({
    loading: true,
    items: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });
  useEffect(() => {
    if (url) {
      const iframe = iframeRef.current;
      iframe.src = url; // Установка URL в iframe
    }
  }, [url]);
  const onPriceUp = useCallback((price) => {
    createTransaction({ price: price, action: "PriceUp" })
      .then((res) => {
        if (res.data?.link) {
          setUrl(res.data.link);
          setShowAdvice(true);

        }
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      });
  }, [data]);
  const onPriceDown = useCallback(() => {
    if (!data.cardNumber) {
      return NotificationManager.error(
        "Введите номер карты"
      )
    }
    if (!data?.price) {
      return NotificationManager.error(
        "Введите сумму"
      )
    }
    if (data?.price < 50) {
      return NotificationManager.error(
        "Минимальная сумма вывода 50 рублей"
      )
    }
    if (parseFloat(price) > parseFloat(user?.currentCash)) {
      return NotificationManager.error(
        "На данный момент можно вывести максимум " + user.currentCash
      )
    }
    setLoader(true)
    createTransaction({ ...data, action: "PriceDown" })
      .then((res) => {
        NotificationManager.success(
          "Заявка на вывод средств отправлена"
        )
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      })
      .finally(() => setLoader(false))
  }, [data, user]);
  useEffect(() => {
    getTransactions({ page: currentPage, size: 10 })
      .then((res) => {
        setTransactions((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [currentPage]);
  const [balanceSection, setBalanceSection] = useState(2);

  if (transactions.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="Финансы" />
      <ReturnTitle link="/account" title="Финансы" />

      <div className="tabs-group mb-4 mb-sm-5">
        <button type="button" className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
          Баланс
        </button>
        <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
          История списаний и пополнений
        </button>
      </div>
      {tab === 0 ? (
        <form action="">

          <h4>Пополнить</h4>
          <fieldset>
            <legend>Сумма:</legend>
            <div className="d-flex align-items-center">
              <div className="me-2 me-sm-4">Укажите необходимую сумму:</div>
              <input
                type="number"
                placeholder="0"
                value={price}
                onChange={(e) => { setPrice(e.target.value) }}
              />
              <div className="ms-3">руб.</div>
            </div>

          </fieldset>

          <div className='d-sm-flex gap-3 mt-4 mt-sm-5'>
            <button type="button" disabled={!price || price <= 0} className="btn-5 w-xs-100 mb-2 mb-sm-0" onClick={() => onPriceUp(price)}>
              Пополнить
            </button>

          </div>
          <h4 className='mt-3'>Вывести</h4>
          <fieldset className='mb-3'>
            <legend>Сумма:</legend>
            <div className="d-flex align-items-center">
              <div className="me-2 me-sm-4">Укажите необходимую сумму:</div>
              <input
                type="number"
                placeholder="0"
                value={data?.price}
                onChange={(e) => { setValue("price", e.target.value) }}
              />
              <div className="ms-3">руб.</div>
            </div>

          </fieldset>
          <p>Минимальная сумма вывода 50 рублей</p>
          <p>Комиссия на вывод 1,5 %, но не менее 35 рублей.</p>
          {data.price >= 50 &&
            <>
              <p>На вывод: <strong>{data.price * 1.5 / 100 >= 35 ? data.price - data.price * 1.5 / 100 : data.price - 35}</strong></p>
              <p>Комиссия: <strong>{data.price * 1.5 / 100 >= 35 ? data.price * 1.5 / 100 : 35}</strong></p>
            </>
          }
          <div className='bank-card'>
            <div className='bank-card-front'>
              <div className='line'>
                <span>Номер карты:</span>
                <Input
                  type="text"
                  className='mt-2'
                  mask="9999 9999 9999 9999"
                  onChange={(e) => setValue("cardNumber", e)}
                />
              </div>
            </div>
          </div>
          <div className='d-sm-flex gap-3 mt-4 mt-sm-5'>

            <button type="button" disabled={!data?.cardNumber || !data?.price || data?.price <= 0 || loader} className="btn-5 w-xs-100" onClick={() => onPriceDown()}>
              {loader ? <Loader className="loader-small" size={20} /> : 'Вывести'}
            </button>
          </div>
        </form>
      ) : (
        <div className='d-flex flex-column h-100'>
          <h6>Ваши списания и пополнения:</h6>
          <Table responsive borderless className="my-3 my-sm-4 h-100">
            <tbody>
              {transactions.loading ? (
                <tr>
                  <td className='py-5 text-center text-muted'>Загрузка операций...</td>
                </tr>
              ) : (
                transactions?.items.map((item, index) => (
                  <Operation key={index} {...item} />
                ))
              )}
            </tbody>
          </Table>
          <NavPagination totalPages={transactions?.pagination?.totalPages} onPageChange={onPageChange} />
        </div>
      )}
      <Modal show={showAdvice} onHide={handleCloseAdvice} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <iframe
              ref={iframeRef}
              title="Payment Frame"
              className="payment-frame"
              src={url}
            />
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default Finance;
